.public-data-header-component .url-link {
  font-size: 0.8em;
  padding-left: 10px; }

.public-data-organizations-component .public-instrument-group-link {
  display: inline-block;
  font-size: 1.5em;
  margin-left: 1em;
  margin-top: 0.5em;
  background-color: #eee;
  padding: 16px;
  border-radius: 10px; }
  .public-data-organizations-component .public-instrument-group-link:hover {
    text-decoration: none;
    background-color: #337ab7;
    color: #fff; }
    .public-data-organizations-component .public-instrument-group-link:hover .glyphicon {
      color: #fff; }

.public-data-plot-component .world-map-component {
  width: 100%;
  height: 330px; }

.public-data-filter-component {
  margin-top: 6px; }
  .public-data-filter-component .form-group {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top; }
  .public-data-filter-component .panel {
    clear: both;
    background-color: #e9effc; }
    .public-data-filter-component .panel.non-expanded {
      border: none;
      margin-bottom: 0px; }
  .public-data-filter-component .data-filter-toggle {
    float: right;
    margin-bottom: 6px; }

.entry-component .jumbotron {
  border-radius: 15px !important;
  margin-top: 25px; }

.showroom-component .available-streams {
  background-color: #e9effc; }
  .showroom-component .available-streams .form-group {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0px; }
    .showroom-component .available-streams .form-group .checkbox {
      display: inline-block;
      margin: 0px 0px 0px 6px;
      position: relative;
      top: 4px; }

.showroom-component .active-streams video {
  width: 100%; }

.showroom-component .active-streams.video-1 .player-area {
  display: inline-block;
  width: 100%;
  max-width: 1024px; }

.showroom-component .active-streams.video-2 .player-area, .showroom-component .active-streams.video-3 .player-area, .showroom-component .active-streams.video-4 .player-area {
  display: inline-block;
  width: 48%;
  max-width: 1024px;
  margin-top: 0;
  margin-right: 1%;
  margin-bottom: 1%;
  min-width: 320px; }

.showroom-component .active-streams.video-n .player-area {
  display: inline-block;
  width: 32%;
  max-width: 1024px;
  margin-top: 0;
  margin-right: 1%;
  margin-bottom: 1%;
  min-width: 320px; }

.video-archive-component .video-item {
  float: left;
  padding: 10px;
  border: solid 1px #ccc;
  margin-right: 16px;
  margin-bottom: 16px;
  background: #fafafa;
  cursor: pointer;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15); }
  .video-archive-component .video-item:hover {
    box-shadow: 5px 5px 5px 0px rgba(0, 60, 128, 0.25);
    text-decoration: none; }
  .video-archive-component .video-item .time {
    color: #000;
    font-weight: bold; }
  .video-archive-component .video-item .name {
    color: #000; }
  .video-archive-component .video-item img {
    height: 160px;
    min-width: 200px;
    display: block;
    border: solid 1px #999;
    margin-bottom: 6px; }
  .video-archive-component .video-item .thumb-missing {
    height: 160px;
    width: 200px;
    border: solid 1px #999;
    color: #aaa;
    text-align: center;
    line-height: 160px;
    margin-bottom: 6px; }
  .video-archive-component .video-item .destroy-video {
    margin: 0;
    padding: 0;
    float: right; }
    .video-archive-component .video-item .destroy-video .glyphicon {
      margin: 0;
      padding: 0;
      color: #f30; }
